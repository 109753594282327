<template>
  <div class="bgFFF paddingT20 paddingB20 paddingLR20">
    <el-form
      v-show="!isLook"
      ref="formPage"
      :model="form"
      :rules="rules"
      label-width="110px"
    >
      <el-form-item label="配置平台" prop="configPlatform">
        <el-select
          v-model.trim="form.configPlatform"
          @change="configSelect(form.configPlatform)"
          filterable
          :disabled="isEdit"
          size="15"
        >
          <el-option
            :label="value.desc"
            :value="value.code"
            :key="'configPlatform' + value.code"
            v-for="value in configPlatformList"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('searchModule.Merchant_Name')" prop="tenantName">
        <el-input v-model.trim="form.tenantName" :maxlength="20"></el-input>
      </el-form-item>
      <el-form-item :label="$t('searchModule.Merchant_type')" prop="tenantType">
        <el-select
          v-model.trim="form.tenantType"
          @change="tenantTypeSelect(form.tenantType)"
          placeholder="请选择商户类型"
          :disabled="isEdit"
          filterable
          size="15"
        >
          <template v-if="form.configPlatform == 3">
            <el-option
              :label="value.desc"
              :value="value.code"
              :key="'tenantType' + value.code"
              v-for="value in zHTenantTypeList"
            ></el-option>
          </template>
          <template v-else>
            <el-option
              :label="value.desc"
              :value="value.code"
              :key="'tenantType' + value.code"
              v-for="value in tenantTypeList"
            ></el-option>
          </template>

          <!-- <el-option
            v-if="form.configPlatform == 3"
            :label="'服务商'"
            value="2"
            :key="2"
          ></el-option>
          <el-option
            v-if="form.configPlatform == 3"
            :label="'特约商户'"
            :value="3"
            :key="3"
          ></el-option> -->
          <el-option
            v-if="form.configPlatform == 4"
            :label="'普通商户'"
            :value="1"
            :key="1"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="父商户" prop="region" v-if="form.configPlatform == 1 && form.tenantType == 3">
            <!--<el-select v-model="form.region" placeholder="请选择商户类型">-->
            <!--<el-option label="微信" value="weixin"></el-option>-->
            <!--<el-option label="支付宝" value="zhifubao"></el-option>-->
            <!--</el-select>-->
            <el-select
              v-model.trim="form.region"
              filterable
              :disabled="isEdit"
              size="15"
            >
              <el-option
                :label="value.tenantName"
                :value="value.tenantId"
                :key="value.tenantId"
                v-for="value in fatherList"
              ></el-option>
            </el-select>
          </el-form-item>
      <!--      微信-->
      <div v-if="form.configPlatform == 1">
        <h2>微信公众平台配置</h2>
        <br />
        <el-form-item :label="$t('searchModule.Merchant_ID')" prop="tenantId">
          <el-input v-model.trim="form.tenantId" :disabled="isEdit"></el-input>
        </el-form-item>
        <el-form-item label="APIkey" prop="APIkey" v-if="form.tenantType != 3">
          <el-input v-model.trim="form.APIkey"></el-input>
        </el-form-item>
        <el-form-item label="微信公众号" v-if="form.tenantType != 3">
          <el-select
            v-model.trim="form.wechartnoBound"
            @change="wechartSelect($event, 11)"
            filterable
            size="15"
          >
            <el-option label="请选择" value=""></el-option>
            <el-option
              :label="value.applicationName"
              :value="value.applicationId"
              :key="value.applicationId"
              v-for="value in wechartnoBoundTenantform"
            ></el-option>
          </el-select>
          <span v-show="configShow">
            <span style="padding-left: 20px">APPID：{{ form.tenantId }}</span>
            <span style="padding-left: 20px">APPSecret: {{ form.APIkey }}</span>
            <span
              class="el-icon-check"
              style="padding-left: 20px; color: blue"
            ></span>
          </span>
        </el-form-item>

        <el-form-item label="微信小程序" v-if="form.tenantType != 3">
          <el-select
            v-model.trim="form.applenoBound"
            @change="appleSelect($event, 12)"
            filterable
            size="15"
          >
            <el-option label="请选择" value=""></el-option>
            <el-option
              :label="value.applicationName"
              :value="value.applicationId"
              :key="value.applicationId"
              v-for="value in applenoBoundTenantform"
            ></el-option>
          </el-select>
          <span v-show="appletconfigShow">
            <span style="padding-left: 6px">APPID：{{ form.tenantId }}</span>
            <span style="padding-left: 20px">APPSecret: {{ form.APIkey }}</span>
            <span
              class="el-icon-check"
              style="padding-left: 20px; color: blue"
            ></span>
          </span>
        </el-form-item>

        <el-form-item label="APP" v-if="form.tenantType == 1">
          <el-select
            v-model.trim="form.appBound"
            @change="appSelect($event, 13)"
            filterable
            size="15"
          >
            <el-option label="请选择" value=""></el-option>
            <el-option
              :label="value.applicationName"
              :value="value.applicationId"
              :key="value.applicationId"
              v-for="value in appnoBoundTenantform"
            ></el-option>
            <span v-show="appconfigShow">
              <span style="padding-left: 6px">APPID：{{ form.tenantId }}</span>
              <span style="padding-left: 20px"
                >APPSecret: {{ form.APIkey }}</span
              >
              <span
                class="el-icon-check"
                style="padding-left: 20px; color: blue"
              ></span>
            </span>
          </el-select>
        </el-form-item>
      </div>
      <!--      支付宝-->
      <div v-if="form.configPlatform == 2">
        <h2>支付宝商户配置</h2>
        <br />
        <el-form-item
          label="ALIPAY_PARTNER"
          label-width="180px"
          prop="tenantId"
        >
          <el-input v-model.trim="form.tenantId"></el-input>
        </el-form-item>
        <el-form-item
          label="ALIPAY_PRIVATE_KEY"
          label-width="180px"
          prop="privateKey"
        >
          <el-input v-model.trim="form.privateKey"></el-input>
        </el-form-item>
        <el-form-item
          label="ALIPAY_ALI_PUBLIC_KEY"
          label-width="180px"
          prop="publicKey"
        >
          <el-input v-model.trim="form.publicKey"></el-input>
        </el-form-item>
        <el-form-item label="服务窗">
          <el-select
            v-model.trim="form.ZFB_FuWuChuang"
            @change="AlipaySelect($event, 22)"
            filterable
            size="15"
          >
            <el-option label="请选择" value=""></el-option>
            <el-option
              :label="value.applicationName"
              :value="value.applicationId"
              :key="value.applicationId"
              v-for="value in AlipayBoundTenantform"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="APP-支付宝">
          <el-select
            v-model.trim="form.appBound"
            @change="appSelect($event, 13)"
            filterable
            size="15"
          >
            <el-option label="请选择" value=""></el-option>
            <el-option
              :label="value.applicationName"
              :value="value.applicationId"
              :key="value.applicationId"
              v-for="value in appAlipayBoundTenantform"
            ></el-option>
            <span v-show="appconfigShow">
              <span style="padding-left: 6px">APPID：{{ form.tenantId }}</span>
              <span style="padding-left: 20px"
                >APPSecret: {{ form.APIkey }}</span
              >
              <span
                class="el-icon-check"
                style="padding-left: 20px; color: blue"
              ></span>
            </span>
          </el-select>
        </el-form-item>
      </div>
      <!--      招商银行-->
      <div v-if="form.configPlatform == 3">
        <div v-if="form.tenantType == 2">
          <h2>服务商配置</h2>
          <br />
          <div>
            <el-form-item
              v-for="(zhitem, zhi) in zhParentMerchantList"
              :key="zhi"
              :label="zhitem.label"
              :prop="zhitem.key"
            >
              <el-input class="mr10" v-model.trim="form[zhitem.key]"></el-input>
              <!-- <el-button
              v-if="zhitem.isUpdate && zhitem.disabled"
              type="primary"
              @click.prevent="zhitem.disabled = false"
              >更新</el-button
            >
            <el-button
              v-if="zhitem.isUpdate && !zhitem.disabled"
              type="primary"
              @click.prevent="updateApplicationMid(zhitem.key, zhi)"
              >完成</el-button
            > -->
              <!-- <el-button
              v-if="zhitem.isUpdate && !zhitem.disabled"
              type="primary"
              @click.prevent="updateApplicationMid('onlineMid')"
              >完成</el-button> -->
            </el-form-item>
          </div>
          <!-- <el-form-item label="线上商户ID" prop="tenantId">
          <el-input
            class="mr10"
            v-model.trim="form.onlineMid"
            :disabled="isEditOnlineMid"
          ></el-input>
          <el-button
            v-if="isEditOnlineMid"
            type="primary"
            @click.prevent="isEditOnlineMid = false"
            >更新</el-button
          >
          <el-button
            v-if="!isEditOnlineMid"
            type="primary"
            @click.prevent="updateApplicationMid('onlineMid')"
            >完成</el-button
          >
        </el-form-item>
        <el-form-item label="线下商户ID" prop="tenantId">
          <el-input
            class="mr10"
            v-model.trim="form.offlineMid"
            :disabled="isEditOfflineMid"
          ></el-input>
          <el-button
            v-if="isEditOfflineMid"
            type="primary"
            @click.prevent="isEditOfflineMid = false"
            >更新</el-button
          >
          <el-button
            v-if="!isEditOfflineMid"
            type="primary"
            @click.prevent="updateApplicationMid('offlineMid')"
            >完成</el-button
          >
        </el-form-item> -->
          <h2>支付渠道配置</h2>
          <br />
          <el-form-item label="应用选择" prop="CMB_ApplicationId">
            <el-select
              v-model.trim="form.CMB_ApplicationId"
              @change="ZhSelect($event)"
              filterable
              size="15"
            >
              <el-option label="请选择" value=""></el-option>
              <el-option
                :label="value.applicationName"
                :value="value.applicationId"
                :key="value.applicationId"
                v-for="value in ZhBoundTenantform"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="微信公众号">
          <el-select
            v-model.trim="form.wechartnoBound"
            @change="wechartSelect($event, 11)"
            filterable
            size="15"
          >
            <el-option label="请选择" value=""></el-option>
            <el-option
              :label="value.applicationName"
              :value="value.applicationId"
              :key="value.applicationId"
              v-for="value in wechartnoBoundTenantform"
            ></el-option>
          </el-select>
          <span v-show="configShow">
            <span style="padding-left: 20px">APPID：{{ form.tenantId }}</span>
            <span style="padding-left: 20px">APPSecret: {{ form.APIkey }}</span>
            <span
              class="el-icon-check"
              style="padding-left: 20px; color: blue"
            ></span>
          </span>
        </el-form-item>

        <el-form-item label="微信小程序">
          <el-select
            v-model.trim="form.applenoBound"
            @change="appleSelect($event, 12)"
            filterable
            size="15"
          >
            <el-option label="请选择" value=""></el-option>
            <el-option
              :label="value.applicationName"
              :value="value.applicationId"
              :key="value.applicationId"
              v-for="value in applenoBoundTenantform"
            ></el-option>
          </el-select>
          <span v-show="appletconfigShow">
            <span style="padding-left: 6px">APPID：{{ form.tenantId }}</span>
            <span style="padding-left: 20px">APPSecret: {{ form.APIkey }}</span>
            <span
              class="el-icon-check"
              style="padding-left: 20px; color: blue"
            ></span>
          </span>
        </el-form-item>

        <el-form-item label="服务窗">
          <el-select
            v-model.trim="form.ZFB_FuWuChuang"
            @change="AlipaySelect($event, 22)"
            filterable
            size="15"
          >
            <el-option label="请选择" value=""></el-option>
            <el-option
              :label="value.applicationName"
              :value="value.applicationId"
              :key="value.applicationId"
              v-for="value in AlipayBoundTenantform"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="APP-支付宝">
          <el-select
            v-model.trim="form.appBound"
            @change="appSelect($event, 13)"
            filterable
            size="15"
          >
            <el-option label="请选择" value=""></el-option>
            <el-option
              :label="value.applicationName"
              :value="value.applicationId"
              :key="value.applicationId"
              v-for="value in appnoBoundTenantform"
            ></el-option>
            <span v-show="appconfigShow">
              <span style="padding-left: 6px">APPID：{{ form.tenantId }}</span>
              <span style="padding-left: 20px"
                >APPSecret: {{ form.APIkey }}</span
              >
              <span
                class="el-icon-check"
                style="padding-left: 20px; color: blue"
              ></span>
            </span>
          </el-select>
        </el-form-item> -->
        </div>
        <!--      招商银行-->
        <div v-if="form.tenantType == 3">
          <h2>特约商户配置</h2>
          <br />
          <el-form-item label="主商户名称" prop="region">
            <!--<el-select v-model="form.region" placeholder="请选择商户类型">-->
            <!--<el-option label="微信" value="weixin"></el-option>-->
            <!--<el-option label="支付宝" value="zhifubao"></el-option>-->
            <!--</el-select>-->
            <el-select
              v-model.trim="form.region"
              filterable
              :disabled="isEdit"
              size="15"
            >
              <el-option
                :label="value.tenantName"
                :value="value.tenantId"
                :key="value.tenantId"
                v-for="value in fatherList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-for="(zhitem, zhi) in zhchildMerchantList"
            :key="zhi"
            :label="zhitem.label"
            :prop="zhitem.key"
          >
            <el-input class="mr10" v-model.trim="form[zhitem.key]"></el-input>
            <!-- <el-button
            v-if="zhitem.isUpdate && zhitem.disabled"
            type="primary"
            @click.prevent="zhitem.disabled = false"
            >更新</el-button
          >
          <el-button
            v-if="zhitem.isUpdate && !zhitem.disabled"
            type="primary"
            @click.prevent="updateApplicationMid(zhitem.key, zhi)"
            >完成</el-button
          > -->
          </el-form-item>
          <!-- <el-form-item label="线上商户ID" prop="onlineSubMid">
          <el-input
            v-model.trim="form.onlineSubMid"
            :disabled="isEditOnlineSubMid"
            class="mr10"
          ></el-input>
          <el-button
            v-if="isEditOnlineSubMid"
            type="primary"
            @click.prevent="isEditOnlineSubMid = false"
            >更新</el-button
          >
          <el-button
            v-if="!isEditOnlineSubMid"
            type="primary"
            @click.prevent="updateApplicationMid('onlineSubMid')"
            >完成</el-button
          >
        </el-form-item>
        <el-form-item label="线下商户ID" prop="offlineSubMid">
          <el-input
            v-model.trim="form.offlineSubMid"
            :disabled="isEditOfflineSubMid"
            class="mr10"
          ></el-input>
          <el-button
            v-if="isEditOfflineSubMid"
            type="primary"
            @click.prevent="isEditOfflineSubMid = false"
            >更新</el-button
          >
          <el-button
            v-if="!isEditOfflineSubMid"
            type="primary"
            @click.prevent="updateApplicationMid('offlineSubMid')"
            >完成</el-button
          >
        </el-form-item> -->
          <el-form-item v-if="isEdit" label="企业签约状态">
            <span
              v-if="
                status.contractState == 'not_sign' ||
                status.contractState == 'signing' ||
                status.contractState == ''
              "
              class="status-fail"
              >未完成</span
            >
            <span class="status-fail" v-if="status.contractState == 'sign_fail'"
              >审核失败</span
            >
            <span
              class="status-success"
              v-if="status.contractState == 'sign_success'"
              >签约完成</span
            >
            <span
              class="status-fail"
              v-if="status.contractState == 'sign_timeout'"
              >审核超时</span
            >
            <el-button
              v-if="
                status.contractState == 'not_sign' ||
                status.contractState == 'signing' ||
                status.contractState == ''
              "
              class="ml10"
              type="primary"
              @click.prevent="handleRealName"
              >前往企业签约</el-button
            >
            <el-button
              v-if="status.contractState == 'sign_fail'"
              class="ml10"
              type="primary"
              @click.prevent="handleRealName"
              >重新签约</el-button
            >
          </el-form-item>
          <!-- <el-form-item
          label="申请状态"
          v-if="status.applyStatus && status.applyStatus != '03'"
          >{{ status.applyStatusMsg }}</el-form-item
        >
        <el-form-item
          label="短信签约状态"
          v-if="status.contractState && status.applyStatus != '03'"
          >{{ status.contractStateMsg }}</el-form-item
        > -->
        </div>
      </div>
      <!--      西安银行-->
      <!-- <div v-if="form.configPlatform == 4">
        <el-form-item :label="$t('searchModule.Merchant_ID')" prop="tenantId">
          <el-input v-model.trim="form.tenantId" :disabled="isEdit"></el-input>
        </el-form-item>
        <el-form-item label="APIkey" prop="APIkey" v-if="form.tenantType != 3">
          <el-input v-model.trim="form.APIkey"></el-input>
        </el-form-item>
        <el-form-item label="银行应用">
          <el-select filterable size="15"></el-select>
        </el-form-item>
      </div> -->
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{ $t('button.preservation') }}</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
      </el-form-item>
    </el-form>
    <el-form v-show="isLook" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="配置平台">
        {{
          form.configPlatform == 1
            ? "微信"
            : form.configPlatform == 2
            ? "支付宝"
            : ""
        }}
        <span v-if="form.configPlatform == 3">招商银行</span>
      </el-form-item>
      <el-form-item :label="$t('searchModule.Merchant_Name')">
        {{ form.tenantName }}
      </el-form-item>
      <el-form-item :label="$t('searchModule.Merchant_type')">
        {{
          form.tenantType == 1
            ? "普通商户"
            : form.tenantType == 2
            ? "服务商"
            : form.tenantType == 3
            ? "特约商户"
            : ""
        }}
      </el-form-item>
      <!-- 招行 -->
      <div v-if="form.configPlatform == 3">
        <div v-if="form.tenantType == 2">
          <el-form-item
            v-for="(item, i) in zhParentMerchantList"
            :key="i"
            :label="item.label"
          >
            {{ form[item.key] }}
          </el-form-item>
          <el-form-item label="应用选择">
            {{ form.CMB_ApplicationIdName }}
          </el-form-item>
        </div>
        <div v-if="form.tenantType == 3">
          <el-form-item
            v-for="(item, i) in zhchildMerchantList"
            :key="i"
            :label="item.label"
          >
            {{ form[item.key] }}
          </el-form-item>
          <el-form-item label="主商户名称">
            <!-- {{form.region}} -->
            <!-- {{ form.region | initRegion(fatherList) }} -->
            {{ form.region | initRegion(fatherList) }}
          </el-form-item>
          <el-form-item label="企业签约状态">
            <span
              class="status-fail"
              v-if="
                status.contractState == 'not_sign' ||
                status.contractState == 'signing'
              "
              >未完成</span
            >
            <span class="status-fail" v-if="status.contractState == 'sign_fail'"
              >审核失败</span
            >
            <span
              class="status-success"
              v-if="status.contractState == 'sign_success'"
              >签约完成</span
            >
            <span
              class="status-fail"
              v-if="status.contractState == 'sign_timeout'"
              >审核超时</span
            >
          </el-form-item>
          <!-- <el-form-item label="申请状态">
          {{ status.applyStatusMsg }}
        </el-form-item>
        <el-form-item label="短信签约状态">
          {{ status.contractStateMsg }}
        </el-form-item> -->
          <el-tabs v-model="activeName" type="card" @tab-click="aggrementSign">
            <el-tab-pane label="认证信息" name="first">
              <el-form label-width="150px">
                <el-form-item label="法人姓名">
                  {{ certification.legalName }}
                </el-form-item>
                <el-form-item label="法人性别">
                  <span v-if="certification.legalSex == 0">不愿透露</span>
                  <span v-if="certification.legalSex == 1">男</span>
                  <span v-if="certification.legalSex == 2">女</span>
                  <span v-if="certification.legalSex == 9">跨性别</span>
                </el-form-item>
                <el-form-item label="居住地址">
                  {{ certification.legalmanHomeAddr }}
                </el-form-item>
                <el-form-item :label="$t('searchModule.phone_number')">
                  {{ certification.legalMobile }}
                </el-form-item>
                <el-form-item :label="$t('searchModule.mailbox')">
                  {{ certification.legalEmail }}
                </el-form-item>
                <el-form-item label="身份证号">
                  {{ certification.legalIdCardNo }}
                </el-form-item>
                <el-form-item label="证件有效期至">
                  {{
                    certification.legalCardDeadLine == "9999-12-31"
                      ? "长期"
                      : certification.legalCardDeadLine
                  }}
                </el-form-item>
                <el-form-item label="身份证正反面照片">
                  <div
                    v-for="pic in certification.mechantPictureList"
                    :key="pic.mechantPictureId"
                  >
                    <el-image
                      v-if="pic.pictureOssUrl"
                      style="width: 100px; height: 100px"
                      :src="pic.pictureOssUrl"
                      :preview-src-list="srcList"
                    ></el-image>
                  </div>
                </el-form-item>
                <el-form-item label="公司股东">
                  {{ certification.legalShareholdFlag == 1 ? "法人" : "他人" }}
                </el-form-item>
                <div v-if="certification.legalShareholdFlag != 1">
                  <el-form-item label="股东姓名">
                    {{ certification.shareholderName }}
                  </el-form-item>
                  <el-form-item label="身份证号">
                    {{ certification.shareholderCertno }}
                  </el-form-item>
                  <el-form-item label="证件有效期至">
                    {{ certification.shareholderCertExpire }}
                  </el-form-item>
                  <el-form-item label="受益人">
                    {{ certification.legalBenefitFlag == 1 ? "法人" : "多人" }}
                  </el-form-item>
                  <div
                    v-for="(benefit, index) in certification.mechantBenefitList"
                    :key="benefit.benefitId"
                  >
                    <el-divider content-position="left"
                      >受益人{{ index + 1 }}</el-divider
                    >
                    <el-form-item label="受益人姓名">
                      {{ benefit.benefitName }}
                    </el-form-item>
                    <el-form-item label="居住地址">
                      {{ benefit.benefitHomeAddr }}
                    </el-form-item>
                    <el-form-item label="身份证号">
                      {{ benefit.benefitCertNo }}
                    </el-form-item>
                    <el-form-item label="证件有效期至">
                      {{
                        benefit.benefitCertExpire == "9999-12-31"
                          ? "长期"
                          : benefit.benefitCertExpire
                      }}
                    </el-form-item>
                  </div>
                </div>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="营业信息" name="second">
              <el-form label-width="150px">
                <el-form-item label="主体类型">企业</el-form-item>
                <el-form-item label="社会统一信用代码/营业执照号码">
                  {{ business.shopLicenseNo }}
                </el-form-item>
                <el-form-item label="工商名称">
                  {{ business.shopName }}
                </el-form-item>
                <el-form-item label="营业地址">
                  {{ business.address }}
                </el-form-item>
                <el-form-item label="详细地址">
                  {{ business.shopAddrExt }}
                </el-form-item>
                <el-form-item label="营业执照">
                  <el-image
                    v-if="business.image[0]"
                    style="width: 100px; height: 100px"
                    :src="business.image[0].pictureOssUrl"
                    :preview-src-list="srcList2"
                  ></el-image>
                </el-form-item>
                <el-form-item label="营业场所照片">
                  <el-image
                    v-if="business.image[1]"
                    style="width: 100px; height: 100px; margin-right: 10px"
                    :src="business.image[1].pictureOssUrl"
                    :preview-src-list="srcList2"
                  ></el-image>
                  <el-image
                    v-if="business.image[2]"
                    style="width: 100px; height: 100px"
                    :src="business.image[2].pictureOssUrl"
                    :preview-src-list="srcList2"
                  ></el-image>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="账户信息" name="third">
              <el-form label-width="100px">
                <el-form-item label="账户类型">对公账户</el-form-item>
                <el-form-item label="账户号码">
                  {{ account.accountNo }}
                </el-form-item>
                <el-form-item label="开户行">
                  {{ account.accountBankNo }}
                </el-form-item>
                <el-form-item label="对公结算材料">
                  <el-image
                    v-if="account.image[0]"
                    style="width: 100px; height: 100px"
                    :src="account.image[0].pictureOssUrl"
                    :preview-src-list="srcList3"
                  ></el-image>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <!-- <el-tab-pane label="协议信息" name="fourth"></el-tab-pane> -->
          </el-tabs>
        </div>
      </div>
      <!--      微信-->
      <div v-show="form.configPlatform == 1">
        <el-form-item :label="$t('searchModule.Merchant_ID')" prop="tenantId">
          {{ form.tenantId }}
        </el-form-item>
        <el-form-item label="APIkey" prop="APIkey" v-if="form.tenantType != 3">
          {{ form.APIkey }}
        </el-form-item>
        <el-form-item label="微信公众号" v-if="form.tenantType != 3">
          {{ form.wechartnoBoundName }}
        </el-form-item>
        <el-form-item label="微信小程序" v-if="form.tenantType != 3">
          {{ form.applenoBoundName }}
        </el-form-item>
        <el-form-item label="APP" v-if="form.tenantType == 1">
          {{ form.appBoundName }}
        </el-form-item>
      </div>
      <!--      支付宝-->
      <div v-show="form.configPlatform == 2">
        <h2>支付宝商户配置</h2>
        <el-form-item
          label="ALIPAY_PARTNER"
          label-width="180px"
          prop="tenantId"
        >
          {{ form.tenantId }}
        </el-form-item>
        <el-form-item
          label="ALIPAY_PRIVATE_KEY"
          label-width="180px"
          prop="privateKey"
        >
          {{ form.privateKey }}
        </el-form-item>
        <el-form-item
          label="ALIPAY_ALI_PUBLIC_KEY"
          label-width="180px"
          prop="publicKey"
        >
          {{ form.publicKey }}
        </el-form-item>
        <el-form-item label="服务窗">
          {{ form.ZFB_FuWuChuangName }}
          <!--<el-select v-model.trim="form.AlipayBound"-->
          <!--@change="AlipaySelect($event, 22)"-->
          <!--filterable-->
          <!--size="15">-->
          <!--<el-option :label="value.applicationName"-->
          <!--:value="value.applicationId"-->
          <!--:key="value.applicationId"-->
          <!--v-for="value in AlipayBoundTenantform"></el-option>-->
          <!--</el-select>-->
        </el-form-item>
        <el-form-item label="APP-支付宝">
          {{ form.ZFB_NomalName }}
        </el-form-item>
      </div>
      <el-form-item>
        <el-button @click="$router.go(-1)">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { regionData } from "element-china-area-data";

export default {
  name: "edit",
  data() {
    return {
      regionData: regionData,
      isEdit: false,
      isEditOnlineSubMid: true,
      isEditOfflineSubMid: true,
      activeName: "first",
      form: {
        appBound: "",
        configPlatform: "",
        tenantType: "",
        APIkey: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        wechartnoBound: "",
        applenoBound: "",
        AlipayBound: "",
        appAlipayBound: "",
        tenantName: "",
        tenantId: "",
        publicKey: "",
        privateKey: "",
        ZFB_Nomal: "",
        ZFB_FuWuChuang: "",
        // onlineMid: "", // 线上父商编号
        // offlineMid: "", // 线下父商编号
        // onlineSubMid: "", // 线上子商编号
        // offlineSubMid: "", // 线下子商编号
        offlineMid: "", // 线下主商户号  商户类型：服务商
        onlineMid: "", // 线上主商户号  商户类型：服务商
        offlineTid: "", // 线下终端号  商户类型：服务商
        onlineTid: "", // 线上终端号   商户类型：服务商
        offlineSubMid: "", // 线下子商户id 商户类型：特约商户
        onlineSubMid: "", // 线上子商户id 商户类型：特约商户
        CMB_ApplicationId: "", // 招行应用
        mchId: "", // 特约商户  商户id
      },
      // 招行 父商户 服务商配置list
      zhParentMerchantList: [
        {
          label: "商户ID",
          key: "tenantId",
        },
        {
          label: "APIkey",
          key: "APIkey",
        },
        {
          label: "线上终端号",
          key: "onlineTid",
        },
        {
          label: "线下终端号",
          key: "offlineTid",
        },
        {
          label: "线上主商户ID",
          key: "onlineMid",
        },
        {
          label: "线下主商户ID",
          key: "offlineMid",
        },
      ],
      // 招行 子商户 特约商户配置list
      zhchildMerchantList: [
        {
          label: "商户ID",
          key: "mchId",
        },
        {
          label: "线上子商户ID",
          key: "onlineSubMid",
        },
        {
          label: "线下子商户ID",
          key: "offlineSubMid",
        },
      ],
      configShow: false,
      appletconfigShow: false,
      appconfigShow: false,
      rules: {
        configPlatform: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        tenantName: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        tenantType: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        tenantId: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        APIkey: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        privateKey: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        publicKey: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        offlineMid: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        onlineMid: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        offlineTid: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        onlineTid: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        CMB_ApplicationId: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        mchId: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
      },
      tenantName: "",
      tenantId: "",
      configPlatformList: [], // 平台配置
      tenantTypeList: [], // 平台配置下关联的商户类型
      zHTenantTypeList: [], // 招行关联的商户类型
      applenoBoundTenantform: [], // 小程序
      wechartnoBoundTenantform: [], // 微信
      appnoBoundTenantform: [], // app
      AlipayBoundTenantform: [], // 支付宝下的服务窗
      appAlipayBoundTenantform: [], // APP-支付宝
      ZhBoundTenantform: [], // 招行
      fatherList: [],
      isLook: false,
      status: {
        applyStatus: "",
        applyStatusMsg: "",
        contractState: "",
        contractStateMsg: "",
      },
      certification: {
        legalName: "",
        legalSex: "",
        legalMobile: "",
        legalEmail: "",
        legalIdCardNo: "",
        legalCardBeginDate: "",
        legalCardDeadLine: "",
        legalBenefitFlag: "",
        legalShareholdFlag: "",
        shareholderName: "",
        shareholderCertno: "",
        shareholderCertExpire: "",
        legalmanHomeAddr: "",
        mechantBenefitList: [],
        mechantPictureList: [],
      },
      business: {
        shopName: "",
        businessAddress: "",
        shopAddrExt: "",
        shopLicenseNo: "",
        image: [],
        address: "",
      },
      account: {
        accountName: "",
        accountCity: [],
        accountNo: "",
        accountBankNo: "",
        transAmount: "",
        accountAuthStatus: "",
        umsRegId: "",
        image: [],
      },
      srcList: [],
      srcList2: [],
      srcList3: [],
    };
  },
  methods: {
    // 企业签约
    handleRealName() {
      // console.log(this.form);
      const { mchId } = this.form;
      if (!mchId) {
        this.$alert("请填写商户ID", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      // this.$router.push(`/realName?tenantId=${tenantId}`);
      this.$router.push(`/realName?tenantId=${mchId}`);
    },
    pageDetailList() {
      this.$axios
        .get("/acb/2.0/tenant/detail", {
          data: {
            tenantId: this.$route.query.tenantId,
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            res.value.APIkey = res.value.attributes.apiKey;
            res.value.privateKey = res.value.attributes.privateKey;
            res.value.publicKey = res.value.attributes.publicKey;
            res.value.wechartnoBoundName = res.value.attributes.WX_WechatName;
            res.value.applenoBoundName = res.value.attributes.WX_AppletName;
            res.value.appBoundName = res.value.attributes.WX_APPName;
            res.value.ZFB_NomalName = res.value.attributes.ZFB_NomalName;
            res.value.ZFB_FuWuChuangName =
              res.value.attributes.ZFB_FuWuChuangName;
            this.form = res.value;
            this.form.region = res.value.parentTenantId; // 父商户
            this.form.wechartnoBound = res.value.attributes.WX_Wechat; // 公众号
            this.form.applenoBound = res.value.attributes.WX_Applet; // 小程序
            this.form.appBound = res.value.attributes.WX_APP ? res.value.attributes.WX_APP : res.value.ZFB_NomalName;
            this.form.ZFB_Nomal = res.value.attributes.ZFB_Nomal ? res.value.attributes.ZFB_Nomal : this.form.appBound; // 支付宝app
            this.form.ZFB_FuWuChuang = res.value.attributes.ZFB_FuWuChuang; // 支付宝服务窗
            this.form.CMB_ApplicationId = res.value.attributes.CMB_UNION; // 招行应用
            this.form.CMB_ApplicationIdName =
              res.value.attributes.CMB_UNIONName; // 招行应用
            this.configSelect(this.form.configPlatform);
            // this.getfatherList(res.value.parentTenantId);
            console.log(this.form);
            if (this.form.configPlatform === 3) {
              // 招商银行
              let arr = [
                ...this.zhParentMerchantList,
                ...this.zhchildMerchantList,
              ];
              const that = this;
              arr.forEach((item) => {
                if (item.key == "APIkey" || item.key == "tenantId") return;
                const extraParam = res.value.extraParam;
                if (extraParam && JSON.parse(extraParam)[item.key]) {
                  that.$set(
                    that.form,
                    item.key,
                    JSON.parse(extraParam)[item.key]
                  );
                }
              });
              this.form.tenantId = res.value.tenantId || "";
              this.form.mchId = res.value.tenantId || "";
              this.form.region = res.value.parentTenantId || "";
              this.getfatherList(this.form.region);
              // console.log(that.form);

              if (res.value.tenantType == 3) {
                this.getCertification();
                this.getBusinessMessage();
                this.getAccountMessage();
                this.getOpenAccountStatus();
              }
            }
          }
        });
    },
    /* 支付商户配置相关枚举 */
    getenumsList() {
      this.$axios.get("/acb/2.0/tenant/enums").then((res) => {
        if (res.state == 0) {
          this.configPlatformList = res.value.configPlatform; // 平台
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    /* 公众号弹框里面支付宝和微信对应的下来框（没有绑定支付商户的应用列表查询 */
    wechartnoBoundTenantList() {
      this.$axios
        .get("/acb/2.0/application/noBoundTenantList", {
          data: {
            applicationType: 11,
            currentBoundApplicationId: this.form.wechartnoBound
              ? this.form.wechartnoBound
              : "",
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            this.wechartnoBoundTenantform = res.value;
          }
        });
    },
    /* 小程序弹框里面支付宝和微信对应的下来框（没有绑定支付商户的应用列表查询 */
    applenoBoundTenantList() {
      this.$axios
        .get("/acb/2.0/application/noBoundTenantList", {
          data: {
            applicationType: 12,
            currentBoundApplicationId: this.form.applenoBound
              ? this.form.applenoBound
              : "",
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            this.applenoBoundTenantform = res.value;
          }
        });
    },
    // app
    appnoBoundTenantList() {
      this.$axios
        .get("/acb/2.0/application/noBoundTenantList", {
          data: {
            applicationType: 13,
            currentBoundApplicationId: this.form.appBound
              ? this.form.appBound
              : "",
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            this.appnoBoundTenantform = res.value;
          }
        });
    },
    /* 支付宝下的服务窗（没有绑定支付商户的应用列表查询 */
    AlipaynoBoundTenantList() {
      this.$axios
        .get("/acb/2.0/application/noBoundTenantList", {
          data: {
            applicationType: 22,
            currentBoundApplicationId: this.form.ZFB_FuWuChuang
              ? this.form.ZFB_FuWuChuang
              : "",
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            this.AlipayBoundTenantform = res.value;
          }
        });
    },
    /* APP-支付宝（没有绑定支付商户的应用列表查询 */
    appAlipaynoBoundTenantList() {
      this.$axios
        .get("/acb/2.0/application/noBoundTenantList", {
          data: {
            applicationType: 21,
            currentBoundApplicationId: this.form.ZFB_Nomal
              ? this.form.ZFB_Nomal
              : "",
          },
        })
        .then((res) => {
          console.log('res123123-->', res);
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            this.appAlipayBoundTenantform = res.value;
          }
        });
    },
    /* 招行应用列表查询 */
    zhAlipaynoBoundTenantList() {
      this.$axios
        .get("/acb/2.0/application/noBoundTenantList", {
          data: {
            applicationType: 31,
            currentBoundApplicationId: this.form.CMB_ApplicationId
              ? this.form.CMB_ApplicationId
              : "",
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            this.ZhBoundTenantform = res.value;
          }
        });
    },
    // 支付商户关联微信应用校验
    checkList(event, type) {
      if (!this.form.tenantId) {
        this.$alert("商户收入ID不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      console.log(this.form);
      // if (!this.form.APIkey) {
      //   this.$alert("APIkey不能为空", this.$t('pop_up.Tips'), {
      //     confirmButtonText: this.$t('pop_up.Determine'),
      //   });
      //   return false;
      // }
      this.$axios
        .post("/acb/2.0/tenant/wechat/check", {
          data: {
            tenantId: this.form.tenantId, // 支付商户id
            tenantName: this.form.tenantName, // 支付商户名称
            tenantType: this.form.tenantType, // 商户类型
            applicationType: type, // 应用类型
            applicationId: event // 应用id
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            if (type == 11) {
              this.configShow = true;
            } else if (type == 12) {
              this.appletconfigShow = true;
            } else if (type == 13) {
              this.appconfigShow = true;
            }
          }
        });
    },
    // 支付宝应用校验
    appaletcheckList(event, type) {
      this.$axios
        .post("/acb/2.0/tenant/aliPay/check", {
          data: {
            tenantId: this.form.tenantId, // 支付商户id
            tenantName: this.form.tenantName, // 支付商户名称
            tenantType: this.form.tenantType, // 商户类型
            applicationType: type, // 应用类型
            applicationId: event, // 应用id
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            // this.appletconfigShow = true
          }
        });
    },
    // 服务窗和app支付宝应用校验
    AlipaycheckList(event, type) {
      this.$axios
        .post("/acb/2.0/tenant/aliPay/check", {
          data: {
            tenantId: this.form.tenantId, // 支付商户id
            tenantName: this.form.tenantName, // 支付商户名称
            tenantType: this.form.tenantType, // 商户类型
            applicationType: type, // 应用类型
            applicationId: event, // 应用id
            privateKey: this.form.privateKey,
            publicKey: this.form.publicKey,
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            this.applenoBoundTenantform = res.value;
          }
        });
    },
    // 父商户下拉菜单
    getfatherList(parentTenantId) {
      const { configPlatform, tenantType } = this.form;
      this.$axios
        .get("/acb/2.0/tenant/list", {
          data: {
            configPlatform: configPlatform,
            tenantType: 2,
            tenantId: parentTenantId,
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            this.fatherList = res.value.list;
            // for (var i = 0; i < this.fatherList.length; i++) {
            //   if (parentTenantId == this.fatherList[i].tenantId) {
            //     this.form.region = this.fatherList[i].tenantName;
            //   }
            // }
          }
        });
    },
    // 公众号下拉框切换
    wechartSelect(event, type) {
      this.checkList(event, 11);
      this.$forceUpdate();
    },

    // 小程序下拉框切换
    appleSelect(event, type) {
      this.form.applenoBound = event;
      this.checkList(event, 12);
      this.$forceUpdate();
    },
    // APP下拉框切换
    appSelect(event, type) {
      console.log('123123', event);
      this.form.appBound = event;
      this.form.ZFB_Nomal = event;
      // if (this.$route.query.state != "add") {
      //   this.form.ZFB_Nomal = event;
      // }
      this.checkList(event, 13);
      this.$forceUpdate();
    },
    // 招行应用下拉框切换
    ZhSelect(event) {
      this.form.CMB_ApplicationId = event;
      this.$forceUpdate();
    },
    // 支付宝服务窗下拉框切换
    AlipaySelect(event, type) {
      this.form.ZFB_FuWuChuang = event;
      this.AlipaycheckList(event, 22);
      this.$forceUpdate();
    },
    // APP-支付宝服务窗下拉框切换
    appAlipaySelect(event, type) {
      console.log('event', event);
      this.form.ZFB_Nomal = event;
      this.AlipaycheckList(event, 21);
      this.$forceUpdate();
    },
    // 切换配置平台
    configSelect(code) {
      for (var i = 0; i < this.configPlatformList.length; i++) {
        if (code == this.configPlatformList[i].code) {
          this.tenantTypeList = this.configPlatformList[i].tenantTypes;
          let zhArr = [];
          this.configPlatformList[i].tenantTypes.forEach((item) => {
            // 招行筛除code 为1  普通商户
            if (item.code != 1) {
              zhArr.push(item);
            }
          });
          this.zHTenantTypeList = zhArr;
        }
      }
      if (code == 1) {
        // 微信
        this.getfatherList();
        this.wechartnoBoundTenantList();
        this.applenoBoundTenantList();
        this.appnoBoundTenantList();
      } else if (code == 2) {
        // 支付宝
        this.appAlipaynoBoundTenantList();
        this.AlipaynoBoundTenantList();
      } else if (code == 3) {
        this.form.region = "";
        this.getfatherList();
        // 招行
        this.zhAlipaynoBoundTenantList();
      }
    },
    // 切换商户类型
    tenantTypeSelect(type) {
      if (type == 3) {
        this.getfatherList();
      }
    },
    // 支付宝的添加和修改
    aliPay() {
      console.log(this.form);
      // this.form.ZFB_Nomal = this.form.appBound
      if (this.form.configPlatform == 2) {
        if (this.form.ZFB_Nomal == "" && this.form.ZFB_FuWuChuang == "") {
          this.$alert("至少选一个支付宝平台配置", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return false;
        }
      }
      console.log('1111111---》', this.form.ZFB_Nomal);
      let url = this.isEdit
        ? "/acb/2.0/tenant/aliPay/update"
        : "/acb/2.0/tenant/aliPay/add";
      this.$axios
        .post(url, {
          data: {
            tenantId: this.form.tenantId, // 支付商户id
            tenantName: this.form.tenantName, // 支付商户名称
            tenantType: this.form.tenantType, // 商户类型
            privateKey: this.form.privateKey,
            publicKey: this.form.publicKey,
            ZFB_Nomal: this.form.ZFB_Nomal,
            ZFB_FuWuChuang: this.form.ZFB_FuWuChuang,
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              this.$router.go(-1);
            });
          }
        });
    },
    // 微信添加和修改
    wechart() {
      if (this.form.configPlatform == 1 && !this.form.region) {
        if (
          this.form.applenoBound == "" &&
          this.form.wechartnoBound == "" &&
          this.form.appBound == ""
        ) {
          this.$alert("至少选一个微信平台配置", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return false;
        }
      }
      this.$refs["formPage"].validate((valid) => {
        if (valid) {
          let url = this.isEdit
            ? "/acb/2.0/tenant/wechat/update"
            : "/acb/2.0/tenant/wechat/add";
            // console.log(this.form.region)
          this.$axios
            .post(url, {
              data: {
                tenantId: this.form.tenantId, // 支付商户id
                tenantName: this.form.tenantName, // 支付商户名称
                tenantType: this.form.tenantType, // 商户类型
                apiKey: this.form.APIkey,
                parentTenantId: this.form.region,
                WX_Wechat: this.form.wechartnoBound,
                WX_Applet: this.form.applenoBound,
                WX_APP: this.form.appBound,
              },
            })
            .then((res) => {
              if (res.state != 0) {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                }).then(() => {
                  this.$router.go(-1);
                });
              }
            });
        }
      });
    },
    // 招行保存
    ZHSave() {
      if (this.form.tenantType == 2 && this.form.CMB_ApplicationId == "") {
        this.$alert("请选择支付渠道应用", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      if (this.form.tenantType == 3 && this.form.region == "") {
        this.$alert("请选择主商户名称", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      this.$refs["formPage"].validate((valid) => {
        if (valid) {
          let url = this.isEdit
            ? "/acb/2.0/tenant/cmb/update"
            : "/acb/2.0/tenant/cmb/add";
          const params = {
            tenantId: this.form.tenantId || this.form.mchId, // 支付商户id
            tenantName: this.form.tenantName, // 支付商户名称
            tenantType: this.form.tenantType, // 商户类型
            parentTenantId: this.form.region, // 主商户名称 父商户
            apiKey: this.form.APIkey, // 产品秘钥
            offlineMid: this.form.offlineMid, // 线下主商户号
            onlineMid: this.form.onlineMid, // 线上主商户号
            offlineTid: this.form.offlineTid, // 线下终端号
            onlineTid: this.form.onlineTid, // 线上终端号
            offlineSubMid: this.form.offlineSubMid, // 线下子商户id
            onlineSubMid: this.form.onlineSubMid, // 线上子商户id
            CMB_ApplicationId: this.form.CMB_ApplicationId, // 招行应用
            mchId: this.form.mchId, // 特约商户  商户id
          };
          // console.log(params);
          const opt = {
            url: url,
            method: "post",
            data: params,
            // config: {
            //   headers: {
            //     "Content-Type": "application/json;charset=UTF-8",
            //   },
            // },
            success: (res) => {
              if (res.state != 0) {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                }).then(() => {
                  this.$router.go(-1);
                });
              }
            },
          };
          this.$request(opt);
        }
      });
    },
    onSubmit() {
      this.$refs["formPage"].validate((valid) => {
        if (valid) {
          // 微信
          if (this.form.configPlatform == 1) {
            this.wechart();
          } else if (this.form.configPlatform == 2) {
            // 支付宝
            this.aliPay();
          } else if (this.form.configPlatform == 3) {
            // 招行
            this.ZHSave();
          }
        }
      });
    },
    updateApplicationMid(type, index = 0) {
      this.$refs["formPage"].validate((valid, res) => {
        if (!Object.prototype.hasOwnProperty.call(res, type)) {
          // 单独验证
          let url = "/acb/2.0/tenant/cmb/updateApplicationMid";
          let params = {
            tenantId: this.form.tenantId,
          };
          params[type] = this.form[type];
          this.$axios
            .post(url, {
              data: params,
            })
            .then((res) => {
              if (res.state != 0) {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              } else {
                const item = {
                  ...this.zhParentMerchantList[index],
                  disabled: true,
                };
                this.$set(this.zhParentMerchantList, index, item);
              }
            });
        }
      });
    },
    getOpenAccountStatus() {
      this.$axios
        .get("/acb/2.0/tenant/cmb/queryOpenAccountStatus", {
          data: {
            tenantId: this.form.tenantId, // 支付商户id
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            this.status = {
              applyStatus: res.value.applyStatus,
              applyStatusMsg: res.value.applyStatusMsg,
              contractState: res.value.contractState,
              contractStateMsg: res.value.contractStateMsg,
            };
          }
        });
    },
    getCertification() {
      this.$axios
        .get(
          "/acb/2.0/tenant/cmb/mechantCertification/byTenantId/" +
            this.form.tenantId
        )
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            this.certification = {
              legalName: res.value.legalName,
              legalSex: res.value.legalSex,
              legalMobile: res.value.legalMobile,
              legalEmail: res.value.legalEmail,
              legalIdCardNo: res.value.legalIdCardNo,
              legalCardBeginDate: res.value.legalCardBeginDate,
              legalCardDeadLine: res.value.legalCardDeadLine,
              legalBenefitFlag: res.value.legalBenefitFlag,
              legalShareholdFlag: res.value.legalShareholdFlag,
              shareholderName: res.value.shareholderName,
              shareholderCertno: res.value.shareholderCertno,
              shareholderCertExpire: res.value.shareholderCertExpire,
              legalmanHomeAddr: res.value.legalmanHomeAddr,
              mechantBenefitList: res.value.mechantBenefitList,
              mechantPictureList: res.value.mechantPictureList,
            };
            this.srcList = res.value.mechantPictureList.map((item) => {
              return item.pictureOssUrl;
            });
          }
        });
    },
    getBusinessMessage() {
      this.$axios
        .get("/acb/2.0/tenant/cmb/mechantShop/byTenantId/" + this.form.tenantId)
        .then((res) => {
          if (res.state != 0) {
          } else {
            let city = "";
            for (let i = 0; i < this.regionData.length; i++) {
              if (
                this.regionData[i].value ==
                res.value.shopProvinceId + "0000"
              ) {
                city += this.regionData[i].label;
                for (let j = 0; j < this.regionData[i].children.length; j++) {
                  if (
                    this.regionData[i].children[j].value == res.value.shopCityId
                  ) {
                    city += this.regionData[i].children[j].label;
                    for (
                      let k = 0;
                      k < this.regionData[i].children[j].children.length;
                      k++
                    ) {
                      if (
                        this.regionData[i].children[j].children[k].value ==
                        res.value.shopCountyId
                      ) {
                        city +=
                          this.regionData[i].children[j].children[k].label;
                      }
                    }
                  }
                }
              }
            }
            this.business = {
              shopName: res.value.shopName,
              businessAddress: [
                res.value.shopProvinceId,
                res.value.shopCityId,
                res.value.shopCountyId,
              ],
              shopAddrExt: res.value.shopAddrExt,
              shopLicenseNo: res.value.shopLicenseNo,
              image: res.value.mechantPictureList,
              address: city,
            };
            this.srcList2 = res.value.mechantPictureList.map((item) => {
              return item.pictureOssUrl;
            });
          }
        });
    },
    getAccountMessage() {
      this.$axios
        .get(
          "/acb/2.0/tenant/cmb/mechantAccountApply/byTenantId/" +
            this.form.tenantId
        )
        .then((res) => {
          if (res.state != 0) {
          } else {
            this.account = {
              accountName: res.value.accountName,
              accountNo: res.value.accountNo,
              accountBankNo: res.value.accountBankNo,
              accountAuthStatus: res.value.accountAuthStatus,
              umsRegId: res.value.umsRegId,
              image: res.value.mechantPictureList,
            };
            this.srcList3 = res.value.mechantPictureList.map((item) => {
              return item.pictureOssUrl;
            });
          }
        });
    },
    aggrementSign(e) {
      if (e.name == "fourth") {
        this.$axios
          .get("/acb/2.0/tenant/cmb/aggrementSign", {
            data: {
              tenantId: this.form.tenantId,
            },
          })
          .then((res) => {
            if (res.state != 0) {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            } else {
              window.location.href = res.value;
            }
          });
      }
    },
  },
  created() {
    this.getenumsList();
    if (this.$route.query.state != "add") {
      this.pageDetailList();
    }
  },
  mounted() {
    if (this.$route.query.state == "edit") {
      this.isEdit = true;
    }
    if (this.$route.query.state == "detail") {
      this.isLook = true;
    }
    // this.appnoBoundTenantList()
  },
  filters: {
    initRegion: (val, list) => {
      let str = "";
      for (var i = 0; i < list.length; i++) {
        if (val == list[i].tenantId) {
          str = list[i].tenantName;
        }
      }
      return str;
    },
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.ml10 {
  margin-left: 10px;
}

.content {
  background: #ffffff;
  overflow: hidden;
  border: 1px solid #c0ccda;
  border-radius: 4px;
  padding: 20px;
}

.el-input {
  width: 20%;
}

.mr10 {
  margin-right: 10px;
}

.status-success {
  color: #1ABC9C;
}

.status-fail {
  color: #F73A11;
}
</style>
