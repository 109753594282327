var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bgFFF paddingT20 paddingB20 paddingLR20" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLook,
              expression: "!isLook",
            },
          ],
          ref: "formPage",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "110px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "配置平台", prop: "configPlatform" } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", disabled: _vm.isEdit, size: "15" },
                  on: {
                    change: function ($event) {
                      return _vm.configSelect(_vm.form.configPlatform)
                    },
                  },
                  model: {
                    value: _vm.form.configPlatform,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "configPlatform",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.configPlatform",
                  },
                },
                _vm._l(_vm.configPlatformList, function (value) {
                  return _c("el-option", {
                    key: "configPlatform" + value.code,
                    attrs: { label: value.desc, value: value.code },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("searchModule.Merchant_Name"),
                prop: "tenantName",
              },
            },
            [
              _c("el-input", {
                attrs: { maxlength: 20 },
                model: {
                  value: _vm.form.tenantName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "tenantName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.tenantName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("searchModule.Merchant_type"),
                prop: "tenantType",
              },
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择商户类型",
                    disabled: _vm.isEdit,
                    filterable: "",
                    size: "15",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.tenantTypeSelect(_vm.form.tenantType)
                    },
                  },
                  model: {
                    value: _vm.form.tenantType,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "tenantType",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.tenantType",
                  },
                },
                [
                  _vm.form.configPlatform == 3
                    ? _vm._l(_vm.zHTenantTypeList, function (value) {
                        return _c("el-option", {
                          key: "tenantType" + value.code,
                          attrs: { label: value.desc, value: value.code },
                        })
                      })
                    : _vm._l(_vm.tenantTypeList, function (value) {
                        return _c("el-option", {
                          key: "tenantType" + value.code,
                          attrs: { label: value.desc, value: value.code },
                        })
                      }),
                  _vm.form.configPlatform == 4
                    ? _c("el-option", {
                        key: 1,
                        attrs: { label: "普通商户", value: 1 },
                      })
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm.form.configPlatform == 1 && _vm.form.tenantType == 3
            ? _c(
                "el-form-item",
                { attrs: { label: "父商户", prop: "region" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        disabled: _vm.isEdit,
                        size: "15",
                      },
                      model: {
                        value: _vm.form.region,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form,
                            "region",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.region",
                      },
                    },
                    _vm._l(_vm.fatherList, function (value) {
                      return _c("el-option", {
                        key: value.tenantId,
                        attrs: {
                          label: value.tenantName,
                          value: value.tenantId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.configPlatform == 1
            ? _c(
                "div",
                [
                  _c("h2", [_vm._v("微信公众平台配置")]),
                  _c("br"),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("searchModule.Merchant_ID"),
                        prop: "tenantId",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.isEdit },
                        model: {
                          value: _vm.form.tenantId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "tenantId",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.tenantId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.tenantType != 3
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "APIkey", prop: "APIkey" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.APIkey,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "APIkey",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.APIkey",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.tenantType != 3
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "微信公众号" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { filterable: "", size: "15" },
                              on: {
                                change: function ($event) {
                                  return _vm.wechartSelect($event, 11)
                                },
                              },
                              model: {
                                value: _vm.form.wechartnoBound,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "wechartnoBound",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.wechartnoBound",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "请选择", value: "" },
                              }),
                              _vm._l(
                                _vm.wechartnoBoundTenantform,
                                function (value) {
                                  return _c("el-option", {
                                    key: value.applicationId,
                                    attrs: {
                                      label: value.applicationName,
                                      value: value.applicationId,
                                    },
                                  })
                                }
                              ),
                            ],
                            2
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.configShow,
                                  expression: "configShow",
                                },
                              ],
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "padding-left": "20px" } },
                                [_vm._v("APPID：" + _vm._s(_vm.form.tenantId))]
                              ),
                              _c(
                                "span",
                                { staticStyle: { "padding-left": "20px" } },
                                [
                                  _vm._v(
                                    "APPSecret: " + _vm._s(_vm.form.APIkey)
                                  ),
                                ]
                              ),
                              _c("span", {
                                staticClass: "el-icon-check",
                                staticStyle: {
                                  "padding-left": "20px",
                                  color: "blue",
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.tenantType != 3
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "微信小程序" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { filterable: "", size: "15" },
                              on: {
                                change: function ($event) {
                                  return _vm.appleSelect($event, 12)
                                },
                              },
                              model: {
                                value: _vm.form.applenoBound,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "applenoBound",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.applenoBound",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "请选择", value: "" },
                              }),
                              _vm._l(
                                _vm.applenoBoundTenantform,
                                function (value) {
                                  return _c("el-option", {
                                    key: value.applicationId,
                                    attrs: {
                                      label: value.applicationName,
                                      value: value.applicationId,
                                    },
                                  })
                                }
                              ),
                            ],
                            2
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.appletconfigShow,
                                  expression: "appletconfigShow",
                                },
                              ],
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "padding-left": "6px" } },
                                [_vm._v("APPID：" + _vm._s(_vm.form.tenantId))]
                              ),
                              _c(
                                "span",
                                { staticStyle: { "padding-left": "20px" } },
                                [
                                  _vm._v(
                                    "APPSecret: " + _vm._s(_vm.form.APIkey)
                                  ),
                                ]
                              ),
                              _c("span", {
                                staticClass: "el-icon-check",
                                staticStyle: {
                                  "padding-left": "20px",
                                  color: "blue",
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.tenantType == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "APP" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { filterable: "", size: "15" },
                              on: {
                                change: function ($event) {
                                  return _vm.appSelect($event, 13)
                                },
                              },
                              model: {
                                value: _vm.form.appBound,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "appBound",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.appBound",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "请选择", value: "" },
                              }),
                              _vm._l(
                                _vm.appnoBoundTenantform,
                                function (value) {
                                  return _c("el-option", {
                                    key: value.applicationId,
                                    attrs: {
                                      label: value.applicationName,
                                      value: value.applicationId,
                                    },
                                  })
                                }
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.appconfigShow,
                                      expression: "appconfigShow",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "padding-left": "6px" } },
                                    [
                                      _vm._v(
                                        "APPID：" + _vm._s(_vm.form.tenantId)
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { "padding-left": "20px" } },
                                    [
                                      _vm._v(
                                        "APPSecret: " + _vm._s(_vm.form.APIkey)
                                      ),
                                    ]
                                  ),
                                  _c("span", {
                                    staticClass: "el-icon-check",
                                    staticStyle: {
                                      "padding-left": "20px",
                                      color: "blue",
                                    },
                                  }),
                                ]
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.form.configPlatform == 2
            ? _c(
                "div",
                [
                  _c("h2", [_vm._v("支付宝商户配置")]),
                  _c("br"),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "ALIPAY_PARTNER",
                        "label-width": "180px",
                        prop: "tenantId",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.tenantId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "tenantId",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.tenantId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "ALIPAY_PRIVATE_KEY",
                        "label-width": "180px",
                        prop: "privateKey",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.privateKey,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "privateKey",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.privateKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "ALIPAY_ALI_PUBLIC_KEY",
                        "label-width": "180px",
                        prop: "publicKey",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.publicKey,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "publicKey",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.publicKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "服务窗" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", size: "15" },
                          on: {
                            change: function ($event) {
                              return _vm.AlipaySelect($event, 22)
                            },
                          },
                          model: {
                            value: _vm.form.ZFB_FuWuChuang,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "ZFB_FuWuChuang",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.ZFB_FuWuChuang",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "请选择", value: "" },
                          }),
                          _vm._l(_vm.AlipayBoundTenantform, function (value) {
                            return _c("el-option", {
                              key: value.applicationId,
                              attrs: {
                                label: value.applicationName,
                                value: value.applicationId,
                              },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "APP-支付宝" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", size: "15" },
                          on: {
                            change: function ($event) {
                              return _vm.appSelect($event, 13)
                            },
                          },
                          model: {
                            value: _vm.form.appBound,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "appBound",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.appBound",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "请选择", value: "" },
                          }),
                          _vm._l(
                            _vm.appAlipayBoundTenantform,
                            function (value) {
                              return _c("el-option", {
                                key: value.applicationId,
                                attrs: {
                                  label: value.applicationName,
                                  value: value.applicationId,
                                },
                              })
                            }
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.appconfigShow,
                                  expression: "appconfigShow",
                                },
                              ],
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "padding-left": "6px" } },
                                [_vm._v("APPID：" + _vm._s(_vm.form.tenantId))]
                              ),
                              _c(
                                "span",
                                { staticStyle: { "padding-left": "20px" } },
                                [
                                  _vm._v(
                                    "APPSecret: " + _vm._s(_vm.form.APIkey)
                                  ),
                                ]
                              ),
                              _c("span", {
                                staticClass: "el-icon-check",
                                staticStyle: {
                                  "padding-left": "20px",
                                  color: "blue",
                                },
                              }),
                            ]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.configPlatform == 3
            ? _c("div", [
                _vm.form.tenantType == 2
                  ? _c(
                      "div",
                      [
                        _c("h2", [_vm._v("服务商配置")]),
                        _c("br"),
                        _c(
                          "div",
                          _vm._l(
                            _vm.zhParentMerchantList,
                            function (zhitem, zhi) {
                              return _c(
                                "el-form-item",
                                {
                                  key: zhi,
                                  attrs: {
                                    label: zhitem.label,
                                    prop: zhitem.key,
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "mr10",
                                    model: {
                                      value: _vm.form[zhitem.key],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          zhitem.key,
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form[zhitem.key]",
                                    },
                                  }),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                        _c("h2", [_vm._v("支付渠道配置")]),
                        _c("br"),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "应用选择",
                              prop: "CMB_ApplicationId",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { filterable: "", size: "15" },
                                on: {
                                  change: function ($event) {
                                    return _vm.ZhSelect($event)
                                  },
                                },
                                model: {
                                  value: _vm.form.CMB_ApplicationId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "CMB_ApplicationId",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.CMB_ApplicationId",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "请选择", value: "" },
                                }),
                                _vm._l(_vm.ZhBoundTenantform, function (value) {
                                  return _c("el-option", {
                                    key: value.applicationId,
                                    attrs: {
                                      label: value.applicationName,
                                      value: value.applicationId,
                                    },
                                  })
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.tenantType == 3
                  ? _c(
                      "div",
                      [
                        _c("h2", [_vm._v("特约商户配置")]),
                        _c("br"),
                        _c(
                          "el-form-item",
                          { attrs: { label: "主商户名称", prop: "region" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  disabled: _vm.isEdit,
                                  size: "15",
                                },
                                model: {
                                  value: _vm.form.region,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "region",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.region",
                                },
                              },
                              _vm._l(_vm.fatherList, function (value) {
                                return _c("el-option", {
                                  key: value.tenantId,
                                  attrs: {
                                    label: value.tenantName,
                                    value: value.tenantId,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._l(_vm.zhchildMerchantList, function (zhitem, zhi) {
                          return _c(
                            "el-form-item",
                            {
                              key: zhi,
                              attrs: { label: zhitem.label, prop: zhitem.key },
                            },
                            [
                              _c("el-input", {
                                staticClass: "mr10",
                                model: {
                                  value: _vm.form[zhitem.key],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      zhitem.key,
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form[zhitem.key]",
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        _vm.isEdit
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "企业签约状态" } },
                              [
                                _vm.status.contractState == "not_sign" ||
                                _vm.status.contractState == "signing" ||
                                _vm.status.contractState == ""
                                  ? _c("span", { staticClass: "status-fail" }, [
                                      _vm._v("未完成"),
                                    ])
                                  : _vm._e(),
                                _vm.status.contractState == "sign_fail"
                                  ? _c("span", { staticClass: "status-fail" }, [
                                      _vm._v("审核失败"),
                                    ])
                                  : _vm._e(),
                                _vm.status.contractState == "sign_success"
                                  ? _c(
                                      "span",
                                      { staticClass: "status-success" },
                                      [_vm._v("签约完成")]
                                    )
                                  : _vm._e(),
                                _vm.status.contractState == "sign_timeout"
                                  ? _c("span", { staticClass: "status-fail" }, [
                                      _vm._v("审核超时"),
                                    ])
                                  : _vm._e(),
                                _vm.status.contractState == "not_sign" ||
                                _vm.status.contractState == "signing" ||
                                _vm.status.contractState == ""
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "ml10",
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.handleRealName.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("前往企业签约")]
                                    )
                                  : _vm._e(),
                                _vm.status.contractState == "sign_fail"
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "ml10",
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.handleRealName.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("重新签约")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v(_vm._s(_vm.$t("button.preservation")))]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isLook,
              expression: "isLook",
            },
          ],
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "100px" },
        },
        [
          _c("el-form-item", { attrs: { label: "配置平台" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.form.configPlatform == 1
                    ? "微信"
                    : _vm.form.configPlatform == 2
                    ? "支付宝"
                    : ""
                ) +
                " "
            ),
            _vm.form.configPlatform == 3
              ? _c("span", [_vm._v("招商银行")])
              : _vm._e(),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("searchModule.Merchant_Name") } },
            [_vm._v(" " + _vm._s(_vm.form.tenantName) + " ")]
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("searchModule.Merchant_type") } },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.form.tenantType == 1
                      ? "普通商户"
                      : _vm.form.tenantType == 2
                      ? "服务商"
                      : _vm.form.tenantType == 3
                      ? "特约商户"
                      : ""
                  ) +
                  " "
              ),
            ]
          ),
          _vm.form.configPlatform == 3
            ? _c("div", [
                _vm.form.tenantType == 2
                  ? _c(
                      "div",
                      [
                        _vm._l(_vm.zhParentMerchantList, function (item, i) {
                          return _c(
                            "el-form-item",
                            { key: i, attrs: { label: item.label } },
                            [_vm._v(" " + _vm._s(_vm.form[item.key]) + " ")]
                          )
                        }),
                        _c("el-form-item", { attrs: { label: "应用选择" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.form.CMB_ApplicationIdName) + " "
                          ),
                        ]),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.form.tenantType == 3
                  ? _c(
                      "div",
                      [
                        _vm._l(_vm.zhchildMerchantList, function (item, i) {
                          return _c(
                            "el-form-item",
                            { key: i, attrs: { label: item.label } },
                            [_vm._v(" " + _vm._s(_vm.form[item.key]) + " ")]
                          )
                        }),
                        _c("el-form-item", { attrs: { label: "主商户名称" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("initRegion")(
                                  _vm.form.region,
                                  _vm.fatherList
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "企业签约状态" } },
                          [
                            _vm.status.contractState == "not_sign" ||
                            _vm.status.contractState == "signing"
                              ? _c("span", { staticClass: "status-fail" }, [
                                  _vm._v("未完成"),
                                ])
                              : _vm._e(),
                            _vm.status.contractState == "sign_fail"
                              ? _c("span", { staticClass: "status-fail" }, [
                                  _vm._v("审核失败"),
                                ])
                              : _vm._e(),
                            _vm.status.contractState == "sign_success"
                              ? _c("span", { staticClass: "status-success" }, [
                                  _vm._v("签约完成"),
                                ])
                              : _vm._e(),
                            _vm.status.contractState == "sign_timeout"
                              ? _c("span", { staticClass: "status-fail" }, [
                                  _vm._v("审核超时"),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "el-tabs",
                          {
                            attrs: { type: "card" },
                            on: { "tab-click": _vm.aggrementSign },
                            model: {
                              value: _vm.activeName,
                              callback: function ($$v) {
                                _vm.activeName = $$v
                              },
                              expression: "activeName",
                            },
                          },
                          [
                            _c(
                              "el-tab-pane",
                              { attrs: { label: "认证信息", name: "first" } },
                              [
                                _c(
                                  "el-form",
                                  { attrs: { "label-width": "150px" } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "法人姓名" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.certification.legalName
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "法人性别" } },
                                      [
                                        _vm.certification.legalSex == 0
                                          ? _c("span", [_vm._v("不愿透露")])
                                          : _vm._e(),
                                        _vm.certification.legalSex == 1
                                          ? _c("span", [_vm._v("男")])
                                          : _vm._e(),
                                        _vm.certification.legalSex == 2
                                          ? _c("span", [_vm._v("女")])
                                          : _vm._e(),
                                        _vm.certification.legalSex == 9
                                          ? _c("span", [_vm._v("跨性别")])
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "居住地址" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.certification.legalmanHomeAddr
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "searchModule.phone_number"
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.certification.legalMobile
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t("searchModule.mailbox"),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.certification.legalEmail
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "身份证号" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.certification.legalIdCardNo
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "证件有效期至" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.certification
                                                .legalCardDeadLine ==
                                                "9999-12-31"
                                                ? "长期"
                                                : _vm.certification
                                                    .legalCardDeadLine
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "身份证正反面照片" } },
                                      _vm._l(
                                        _vm.certification.mechantPictureList,
                                        function (pic) {
                                          return _c(
                                            "div",
                                            { key: pic.mechantPictureId },
                                            [
                                              pic.pictureOssUrl
                                                ? _c("el-image", {
                                                    staticStyle: {
                                                      width: "100px",
                                                      height: "100px",
                                                    },
                                                    attrs: {
                                                      src: pic.pictureOssUrl,
                                                      "preview-src-list":
                                                        _vm.srcList,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "公司股东" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.certification
                                                .legalShareholdFlag == 1
                                                ? "法人"
                                                : "他人"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm.certification.legalShareholdFlag != 1
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "股东姓名" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.certification
                                                        .shareholderName
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "身份证号" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.certification
                                                        .shareholderCertno
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "证件有效期至",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.certification
                                                        .shareholderCertExpire
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "受益人" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.certification
                                                        .legalBenefitFlag == 1
                                                        ? "法人"
                                                        : "多人"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _vm._l(
                                              _vm.certification
                                                .mechantBenefitList,
                                              function (benefit, index) {
                                                return _c(
                                                  "div",
                                                  { key: benefit.benefitId },
                                                  [
                                                    _c(
                                                      "el-divider",
                                                      {
                                                        attrs: {
                                                          "content-position":
                                                            "left",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "受益人" +
                                                            _vm._s(index + 1)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "受益人姓名",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              benefit.benefitName
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "居住地址",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              benefit.benefitHomeAddr
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "身份证号",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              benefit.benefitCertNo
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "证件有效期至",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              benefit.benefitCertExpire ==
                                                                "9999-12-31"
                                                                ? "长期"
                                                                : benefit.benefitCertExpire
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-tab-pane",
                              { attrs: { label: "营业信息", name: "second" } },
                              [
                                _c(
                                  "el-form",
                                  { attrs: { "label-width": "150px" } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "主体类型" } },
                                      [_vm._v("企业")]
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label:
                                            "社会统一信用代码/营业执照号码",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.business.shopLicenseNo) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "工商名称" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.business.shopName) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "营业地址" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.business.address) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "详细地址" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.business.shopAddrExt) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "营业执照" } },
                                      [
                                        _vm.business.image[0]
                                          ? _c("el-image", {
                                              staticStyle: {
                                                width: "100px",
                                                height: "100px",
                                              },
                                              attrs: {
                                                src: _vm.business.image[0]
                                                  .pictureOssUrl,
                                                "preview-src-list":
                                                  _vm.srcList2,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "营业场所照片" } },
                                      [
                                        _vm.business.image[1]
                                          ? _c("el-image", {
                                              staticStyle: {
                                                width: "100px",
                                                height: "100px",
                                                "margin-right": "10px",
                                              },
                                              attrs: {
                                                src: _vm.business.image[1]
                                                  .pictureOssUrl,
                                                "preview-src-list":
                                                  _vm.srcList2,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.business.image[2]
                                          ? _c("el-image", {
                                              staticStyle: {
                                                width: "100px",
                                                height: "100px",
                                              },
                                              attrs: {
                                                src: _vm.business.image[2]
                                                  .pictureOssUrl,
                                                "preview-src-list":
                                                  _vm.srcList2,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-tab-pane",
                              { attrs: { label: "账户信息", name: "third" } },
                              [
                                _c(
                                  "el-form",
                                  { attrs: { "label-width": "100px" } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "账户类型" } },
                                      [_vm._v("对公账户")]
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "账户号码" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.account.accountNo) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "开户行" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.account.accountBankNo) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "对公结算材料" } },
                                      [
                                        _vm.account.image[0]
                                          ? _c("el-image", {
                                              staticStyle: {
                                                width: "100px",
                                                height: "100px",
                                              },
                                              attrs: {
                                                src: _vm.account.image[0]
                                                  .pictureOssUrl,
                                                "preview-src-list":
                                                  _vm.srcList3,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.configPlatform == 1,
                  expression: "form.configPlatform == 1",
                },
              ],
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Merchant_ID"),
                    prop: "tenantId",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.form.tenantId) + " ")]
              ),
              _vm.form.tenantType != 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "APIkey", prop: "APIkey" } },
                    [_vm._v(" " + _vm._s(_vm.form.APIkey) + " ")]
                  )
                : _vm._e(),
              _vm.form.tenantType != 3
                ? _c("el-form-item", { attrs: { label: "微信公众号" } }, [
                    _vm._v(" " + _vm._s(_vm.form.wechartnoBoundName) + " "),
                  ])
                : _vm._e(),
              _vm.form.tenantType != 3
                ? _c("el-form-item", { attrs: { label: "微信小程序" } }, [
                    _vm._v(" " + _vm._s(_vm.form.applenoBoundName) + " "),
                  ])
                : _vm._e(),
              _vm.form.tenantType == 1
                ? _c("el-form-item", { attrs: { label: "APP" } }, [
                    _vm._v(" " + _vm._s(_vm.form.appBoundName) + " "),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.configPlatform == 2,
                  expression: "form.configPlatform == 2",
                },
              ],
            },
            [
              _c("h2", [_vm._v("支付宝商户配置")]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "ALIPAY_PARTNER",
                    "label-width": "180px",
                    prop: "tenantId",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.form.tenantId) + " ")]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "ALIPAY_PRIVATE_KEY",
                    "label-width": "180px",
                    prop: "privateKey",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.form.privateKey) + " ")]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "ALIPAY_ALI_PUBLIC_KEY",
                    "label-width": "180px",
                    prop: "publicKey",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.form.publicKey) + " ")]
              ),
              _c("el-form-item", { attrs: { label: "服务窗" } }, [
                _vm._v(" " + _vm._s(_vm.form.ZFB_FuWuChuangName) + " "),
              ]),
              _c("el-form-item", { attrs: { label: "APP-支付宝" } }, [
                _vm._v(" " + _vm._s(_vm.form.ZFB_NomalName) + " "),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }